import React from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { useTeamsFx } from "./sample/lib/useTeamsFx";

import Home from "./Home"
import Tab from "./Tab";
import TabConfig from "./TabConfig";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";

import "./App.css";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { theme, loading } = useTeamsFx();
  return (
    <Provider theme={theme || teamsTheme} styles={{ backgroundColor: "#eeeeee" }}>
      <BrowserRouter >
        <Switch>
        {
          loading ?
            <Route path="/">
              <Loader style={{ margin: 100 }} />
            </Route>:
            <>
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/termsofuse" component={TermsOfUse} />
              <Route exact path="/tab" component={Tab} />
              <Route exact path="/config" component={TabConfig} />
              <Route exact path="/" component={Home}/>
            </>
        }
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}
