import React from "react";
import { Flex, Segment, Header, Divider, Text } from "@fluentui/react-northstar";

/**
 * This component is used to display the required
 * privacy statement which can be found in a link in the
 * about tab.
 */
class Privacy extends React.Component {
  render() {
    return <Flex column >
      <Segment >
        <Header
          align="center"
          content="Privacidade"
          description="App em desenvolvimento..."
        />
      </Segment>
      <Divider />
      <Segment >
        <Text
          align="center"
          content="Uso apenas por pessoal autorizado"
        />
      </Segment>
    </Flex>
  }
}

export default Privacy;
