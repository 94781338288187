import React from "react";
import { Flex, Segment, Header, Divider, Text } from "@fluentui/react-northstar";

/**
 * This component is the home page
 */
class Tab extends React.Component {
  render() {
    return <Flex column >
      <Segment >
        <Header
          align="center"
          content="Gestor Messenger Teams"
          description="Whatsapp integrado no teams da sua empresa!"
        />
      </Segment>
      <Divider />
      <Segment >
        <Text
          align="center"
          content="Gestor Messenger Teams sincroniza os atendimento ao seus clientes pelo WhastApp em conversas dentro de canais das equipes."
        />
        <Text
          align="center"
          content="Deixe a gestão no teams, aproveite toda produtividade corporativa que a microsoft pode embarcar em seu escritório."
        />
        <Text
          align="center"
          content="Todo atendimento pelo WhatsApp é uma tarefa no planner da equipe, com data e responsável, pronto para você continuar integrando seus próprios processos."
        />
      </Segment>
    </Flex>
  }
}

export default Tab;
