import React from "react";
import { Flex, Segment, Header, Divider, Text } from "@fluentui/react-northstar";

/**
 * This component is the home page
 */
class Home extends React.Component {
  render() {
    return <Flex column >
      <Segment >
        <Header
          align="center"
          content="Gestor Messenger Teams"
          description="Whatsapp integrado no teams da sua empresa!"
        />
      </Segment>
      <Divider />
      <Segment >
        <Text
          align="center"
          content="Gestor Messenger Teams sincroniza e extende os atendimento aos seus clientes pelo WhastApp em conversas dentro de canais, além de automatizar a gestão de tarefas de forma integrada ao teams."
        />
      </Segment>
    </Flex>
  }
}

export default Home;
